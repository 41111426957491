import type { Backend } from '@freelancer/datastore/core';
import { OrderByDirection } from '@freelancer/datastore/core';
import type { SeoNavbarCollection } from './seo-navbar.types';

export function SeoNavbarBackend(): Backend<SeoNavbarCollection> {
  return {
    defaultOrder: {
      field: 'id',
      direction: OrderByDirection.ASC,
    },
    fetch: (authUid, ids, query, order) => ({
      endpoint: 'seo-navbar/getSeoNavbar.php',
      isGaf: true,
    }),
    push: undefined,
    set: undefined,
    update: undefined,
    remove: undefined,
  };
}
