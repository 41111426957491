import type {
  CollectionActions,
  CollectionStateSlice,
} from '@freelancer/datastore/core';
import {
  mergeDocuments,
  transformIntoDocuments,
} from '@freelancer/datastore/core';
import { transformSeoNavbar } from './seo-navbar.transformers';
import type { SeoNavbarCollection } from './seo-navbar.types';

export function SeoNavbarReducer(
  state: CollectionStateSlice<SeoNavbarCollection> = {},
  action: CollectionActions<SeoNavbarCollection>,
): CollectionStateSlice<SeoNavbarCollection> {
  switch (action.type) {
    case 'API_FETCH_SUCCESS': {
      if (action.payload.type === 'seoNavbar') {
        const { result, ref, order } = action.payload;
        return mergeDocuments<SeoNavbarCollection>(
          state,
          transformIntoDocuments([result], transformSeoNavbar),
          order,
          ref,
        );
      }
      return state;
    }

    default:
      return state;
  }
}
