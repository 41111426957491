import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DatastoreSeoNavbarModule } from '@freelancer/datastore/collections';
import { PipesModule } from '@freelancer/pipes';
import { TrackingModule } from '@freelancer/tracking';
import { UiModule } from '@freelancer/ui';
import { MegaMenuComponent } from './mega-menu/mega-menu.component';
import { SeoNavbarComponent } from './seo-navbar.component';

@NgModule({
  imports: [
    CommonModule,
    DatastoreSeoNavbarModule,
    PipesModule,
    TrackingModule,
    UiModule,
  ],
  declarations: [MegaMenuComponent, SeoNavbarComponent],
  exports: [SeoNavbarComponent],
})
export class SeoNavbarModule {}
