import { NgModule } from '@angular/core';
import { BackendModule } from '@freelancer/datastore/core';
import { StoreModule } from '@ngrx/store';
import { SeoNavbarBackend } from './seo-navbar.backend';
import { SeoNavbarReducer } from './seo-navbar.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('seoNavbar', SeoNavbarReducer),
    BackendModule.forFeature('seoNavbar', SeoNavbarBackend),
  ],
})
export class DatastoreSeoNavbarModule {}
