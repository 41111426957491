import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SeoNavbarModule } from 'app/navigation/seo-navbar/seo-navbar.module';
import { CompatSeoNavbarComponent } from './compat-seo-navbar.component';

@NgModule({
  imports: [SeoNavbarModule, CommonModule],
  declarations: [CompatSeoNavbarComponent],
})
export class CompatSeoNavbarModule {}
